<template>
	<div class="solution">
		<div class="top">
			<div class="top-img">
				<img
					v-lazy="solutionData.banner"
					:key="solutionData.banner"
					alt="top"
				/>
			</div>
			<div class="top-center">
				<p>{{ solutionData.name }}</p>
				<el-button
					class="banner-btn"
					plain
					@click="
						() => {
							this.$router.push('/formSub')
						}
					"
					>立即咨询</el-button
				>
			</div>
		</div>
		<div class="container">
			<div
				class="containerBg"
				v-lazy:background-image="containerBgUrl"
			></div>
			<div class="content">
				<div class="inner">
					<div class="plan-introduce">
						<div class="title">
							<span class="title-text">方案介绍</span>
							<span class="title-desc"
								>将智能产品因地制宜，达到个性化使用目的</span
							>
						</div>
						<div
							class="plan-introduce-center"
							v-lazy:background-image="introduceBgUrl"
							v-if="!isMobileEnd"
						>
							<div class="plan-introduce-center-content">
								<div class="introduce-img">
									<video controls playsinline v-if="isMp4">
										<!-- MP4 文件 -->
										<source
											:src="fileUrl"
											type="video/mp4"
										/>
									</video>
									<img
										v-else
										v-lazy="solutionData.introductionImg"
										:key="solutionData.introductionImg"
										alt="方案介绍图"
									/>
								</div>
								<div class="introduce-text">
									<div class="introduce-text-name">
										{{ solutionData.name }}
									</div>
									<div class="introduce-text-line"></div>
									<div class="introduce-text-desc">
										{{ solutionData.introduction }}
									</div>
									<el-button
										class="introduce-text-btn"
										@click="
											() => {
												this.$router.push('/formSub')
											}
										"
										>方案咨询</el-button
									>
								</div>
							</div>
						</div>
						<div class="plan-introduce-768" v-else>
							<div class="plan-introduce-768-content">
								<div class="introduce-768-img">
									<div class="introduce-768-img-D"></div>
									<div class="introduce-768-img-T">
										<video
											controls
											playsinline
											v-if="isMp4"
										>
											<!-- MP4 文件 -->
											<source
												:src="fileUrl"
												type="video/mp4"
											/>
										</video>
										<img
											v-else
											v-lazy="
												solutionData.introductionImg
											"
											:key="solutionData.introductionImg"
											alt="方案介绍图"
										/>
									</div>
								</div>
								<div class="introduce-768-text">
									<div class="introduce-768-text-name">
										{{ solutionData.name }}
									</div>
									<div class="introduce-768-text-line"></div>
									<div class="introduce-768-text-desc">
										{{ solutionData.introduction }}
									</div>
									<el-button
										class="introduce-768-text-btn"
										type="primary"
										>方案咨询</el-button
									>
								</div>
							</div>
						</div>
					</div>

					<div class="plan-architecture">
						<div class="title">
							<span class="title-text">系统架构</span>
							<span class="title-desc"
								>将系统架构可视化，带您了解体系节点</span
							>
						</div>
						<div class="plan-architecture-center">
							<img
								v-lazy="solutionData.schemaImg"
								:key="solutionData.schemaImg"
								alt="架构图"
							/>
						</div>
					</div>

					<div class="plan-feature">
						<div class="title">
							<span class="title-text">方案特点</span>
							<span class="title-desc"
								>精准提炼本方案，高效了解其优势</span
							>
						</div>
						<div class="plan-feature-center">
							<div
								class="plan-feature-center-item"
								v-for="(i, index) in solutionData.features"
								:key="index"
							>
								<img
									v-lazy="i.featureImg"
									:key="i.featureImg"
									alt="方案特点"
								/>
								<h5>{{ i.featureName }}</h5>
								<p>{{ i.featureIntroduction }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="left"></div>
			<div class="right"></div>
		</div>
	</div>
</template>

<script>
import { getSolutionDetails } from '@/network/solution/index.js'
export default {
	name: 'solution',
	data() {
		return {
			introduceBgUrl: require('@/assets/img/solution/introduceBg.png'),
			containerBgUrl: require('@/assets/img/containerBg.png'),
			solutionData: {
				name: '', //方案名称
				banner: '', //banner图
				introductionImg: '', //方案介绍图片
				introduction: '', //方案介绍
				schemaImg: '', //架构图
				features: [
					{
						featureName: '', //方案特点
						featureImg: '', //方案特点图片
						featureIntroduction: '', //方案特点具体描述
					},
					{
						featureName: '', //方案特点
						featureImg: '', //方案特点图片
						featureIntroduction: '', //方案特点具体描述
					},
					{
						featureName: '', //方案特点
						featureImg: '', //方案特点图片
						featureIntroduction: '', //方案特点具体描述
					},
				], //方案特点数组
			},
			isMp4: false,
			fileUrl: '',
		}
	},
	watch: {
		// 解决路由跳转同一个页面，携带不同参数，页面不刷新问题
		$route(to, from) {
			this.$store.state.activeIndex = '2'
			if (to.query.id != from.query.id) {
				this.getSolutionData(this.$route.query.id)
			}
		},
	},
	created() {
		this.$store.state.activeIndex = '2'
	},
	mounted() {
		if (this.$route.query.id) {
			this.getSolutionData(this.$route.query.id)
		}
	},
	methods: {
		getSolutionData(id) {
			getSolutionDetails(id)
				.then((res) => {
					if (res.data.code == 200) {
						let formData = res.data.data
						const formDataList = formData.features.map((item) => {
							let obj = Object.assign(item, {
								featureImg: window.baseUrl + item.featureImg,
							})
							return obj
						})
						let url = formData.introductionImg
						let findStr = url.lastIndexOf('.')
						let type = url.slice(findStr)
						if (type == '.mp4') {
							this.isMp4 = true
							this.fileUrl = window.baseUrl + url
						} else {
							this.isMp4 = false
							this.fileUrl = ''
						}

						this.solutionData = {
							name: formData.name,
							banner: this.showPictures(formData.banner),
							introductionImg: this.showPictures(
								formData.introductionImg
							),
							introduction: formData.introduction,
							schemaImg: this.showPictures(formData.schemaImg),
							features: formDataList,
						}
					}
				})
				.catch(() => {})
		},
		showPictures(path) {
			const acceptArr = ['.png', '.jpg']
			let findStr = path.lastIndexOf('.')
			let type = path.slice(findStr)
			if (acceptArr.indexOf(type) != -1) {
				const imgPath = window.baseUrl + path
				return imgPath
			} else if (type == 'mp4') {
				const mp4Path = window.baseUrl + path
				return mp4Path
			} else {
				return ''
			}
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.solution {
	position: relative;
	.top {
		width: 100%;
		overflow: hidden;
		height: 6.76rem;
		margin-bottom: 1.6rem;
		position: relative;
		.top-img {
			width: 100%;
			height: 100%;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.top-center {
			width: calc(100% - 6.4rem);
			min-width: 174px;
			height: 2rem;
			position: absolute;
			left: 3.15rem;
			top: 0;
			bottom: 0;
			margin: auto;
			text-align: left;
			p {
				font-size: 0.64rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 500;
				line-height: 0.72rem;
				color: #ffffff;
				margin-bottom: 0.6rem;
			}
			.banner-btn {
				width: 1.66rem;
				height: 0.42rem;
				background-color: transparent;
				color: #ffffff;
				font-size: 0.18rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				line-height: 0.24rem;
				letter-spacing: 1px;
				border-radius: 0.04rem;
				padding: 0;
				&:hover {
					border: 0.01rem solid #ffffff;
					background: rgba(255, 255, 255, 0.2);
				}
			}
		}
	}
	.container {
		position: relative;
	}
	.containerBg {
		width: 100%;
		height: 10.24rem;
		position: absolute;
		top: 17.44rem;
		z-index: -1;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
	// 三栏都需要浮动
	.center,
	.left,
	.right {
		float: left;
	}
	// 左右两栏固定宽度
	.left,
	.right {
		width: 3.15rem;
		background-color: #fff;
	}
	// 中间栏  因为需要自适应所以设置宽度100%
	.center {
		width: 100%;
	}
	.left {
		margin-left: -100%;
	}
	.right {
		margin-left: -3.15rem;
	}
	.inner {
		margin: 0 3.15rem;
	}

	.content {
		.plan-introduce {
			width: 100%;
			min-height: 5rem;
			margin-bottom: 1.6rem;
			&-center {
				width: calc(100% - 0.6rem);
				height: calc(6.1rem - 1.44rem);
				padding: 0.72rem 0.3rem;
				background-size: 100% 100%;
				&-content {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.introduce-img {
						width: 56%;
						height: 100%;
						img {
							width: 100%;
							height: 100%;
						}
						video {
							width: 100%;
							height: 100%;
						}
					}
					.introduce-text {
						width: calc(44% - 0.3rem);
						height: calc(100% - 0.8rem);
						padding: 0.4rem 0 0.3rem;
						&-name {
							height: 0.24rem;
							font-size: 0.24rem;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 500;
							color: #333333;
							line-height: 0.24rem;
						}
						&-line {
							width: 0.33rem;
							height: 0.04rem;
							background: #4698f6;
							border-radius: 0.2rem;
							margin-top: 0.08rem;
						}
						&-desc {
							min-height: 2.24rem;
							font-size: 0.16rem;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 400;
							color: #666666;
							line-height: 0.32rem;
							margin-top: 0.4rem;
						}
						&-btn {
							width: 1.66rem;
							height: 0.48rem;
							margin-top: 0.4rem;
							font-size: 0.16rem;
							background-color: #127bf2;
							color: #ffffff;
							border-radius: 4px;
							&:hover {
								background-color: #419aff;
							}
						}
					}
				}
			}
			.plan-introduce-768 {
				&-content {
					.introduce-768-img {
						width: 343px;
						height: 241px;
						position: relative;
						&-D {
							width: 169px;
							height: 224px;
							background: #127bf2;
							position: absolute;
							left: 0;
							bottom: 0;
							z-index: -1;
						}
						&-T {
							width: 329px;
							height: 224px;
							padding: 0 0 17px 14px;
							z-index: 9;
							display: flex;
							// justify-content: center;
							align-items: center;
							video {
								width: 100%;
								height: 100%;
							}
						}
					}
					.introduce-768-text {
						width: calc(100% - 32px);
						// height: 260px;
						margin-top: 16px;
						background-color: #f7f8fa;
						padding: 16px;
						&-name {
							font-size: 18px;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 500;
							color: #485163;
							line-height: 24px;
						}
						&-line {
							width: 33px;
							height: 4px;
							background-color: #4698f6;
							border-radius: 237px 237px 237px 237px;
							margin-top: 8px;
						}
						&-desc {
							font-size: 14px;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 400;
							color: #636976;
							line-height: 26px;
							margin-top: 12px;
						}
						&-btn {
							width: 100%;
							height: 32px;
							margin-top: 24px;
							background-color: #127bf2;
							border-color: #127bf2;
						}
					}
				}
			}
		}
		.plan-architecture {
			width: 100%;
			margin-bottom: 1.6rem;
			&-center {
				width: 100%;
				height: 5.7rem;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
		.plan-feature {
			width: 100%;
			margin-bottom: 1.6rem;
			&-center {
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				gap: 0.4rem 0;
				grid-auto-flow: row;
				justify-content: space-around;
				&-item {
					height: 2rem;
					background-color: #ffffff;
					text-align: center;
					padding: 0.4rem 0.6rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					img {
						width: 0.6rem;
						height: 0.6rem;
					}
					h5 {
						height: 0.24rem;
						font-size: 0.24rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 700;
						color: #485163;
						line-height: 0.24rem;
						margin: 0.16rem 0;
					}
					p {
						font-size: 0.16rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 400;
						color: #485163;
						line-height: 0.24rem;
					}
				}
			}
		}
	}
}
.title {
	width: 100%;
	margin-bottom: 0.6rem;
	text-align: center;
	.title-text {
		height: 0.6rem;
		font-size: 0.52rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 500;
		color: #333333;
		line-height: 0.6rem;
		display: block;
	}
	.title-desc {
		display: inline-block;
		font-size: 0.24rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 400;
		color: #666666;
		line-height: 0.32rem;
		margin-top: 0.12rem;
	}
}
@media screen and (max-width: 768px) {
	.solution {
		.top {
			height: 178px;
			.top-center {
				height: 52px;
				left: 40px;
				p {
					font-size: 16px;
					line-height: 22px;
					margin-bottom: 8px;
				}
				.banner-btn {
					width: 59px;
					height: 22px;
					font-size: 10px;
					line-height: 10px;
					border-width: 0.01vw;
					&::after {
						transform: scale(0.5);
					}
				}
			}
		}
		.containerBg {
			top: 942px;
			height: 380px;
		}
		// 左右两栏固定宽度
		.left,
		.right {
			width: 16px;
		}
		.right {
			margin-left: -16px;
		}
		.inner {
			margin: 0 16px;
		}
		.content {
			.plan-feature {
				margin-bottom: 24px;
				&-center {
					gap: 16px 10px;
					&-item {
						height: 100px;
						img {
							width: 40px;
							height: 40px;
						}
						h5 {
							height: 20px;
							font-size: 12px;
							font-weight: 500;
							line-height: 20px;
						}
						p {
							display: none;
						}
					}
				}
			}
		}

		.title {
			.title-text {
				height: 24px;
				font-size: 16px;
				font-weight: 400;
				color: #020814;
				line-height: 24px;
			}
			.title-desc {
				font-size: 10px;
				line-height: 18px;
			}
		}
	}
}
</style>
